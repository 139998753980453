// import { combineReducers } from 'redux';
import orderReducer from './order-reducer';
import productReducer from './product-reducer';
import authReducer from './auth-reducer';
// import order, * as fromOrderReducer from './order-reducer';
// import { ProductWhereIn } from '../../api';
// import NumberFormat from 'react-number-format';
// import { store } from '../../index';




// const rootReducer = combineReducers({
//     order: orderReducer,
//     products: productReducer
// });

export default {
    order: orderReducer,
    products:productReducer,
    auth:authReducer
};

// export default rootReducer;

// untuk mengolah data supaya rapih
export const getOrderCount = state => {
    return Object.keys(state.order).reduce((sum, key) => sum + parseFloat(state.order[key] || 0), 0)
};



