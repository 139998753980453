import * as actionTypes from './actionTypes';
import { ProductWhereIn } from '../../api';
import { store } from '../../index';
import {checkToken} from '../redux-actions/auth-action';


export const fetchProduct = (products) => {
    return {
        type: actionTypes.FETCH_PRODUCT,
        products: products
    }
}

export const startFetchProduct = () => {
    return {
        type: actionTypes.START_FETCHPRODUCT,
    }
}

export const successFetchProduct = () => {
    return {
        type: actionTypes.SUCCESS_FETCHPRODUCT,
    }
}

export const setTotalPrice = (totalPrice) => {
    return {
        type: actionTypes.SET_TOTALPRICE,
        totalPrice: totalPrice
    }
}

export const getTotalPrice = (data) => {
    return dispatch => {
        const content = store.getState().order;
        const product = data;     
        // console.log(product)           

        if (product && product.length > 0 && Object.keys(content).length) {
            //associative array
            const arrProducts = {};
                       
            product.map( (value, index) => {
                const name =  value.id;
                arrProducts[name] = value;
            });

            const summary = Object.keys(content).reduce((sum, key) =>
                sum + arrProducts[key].promoPrice * content[key]
                , 0);

            dispatch(setTotalPrice(summary))
        }else{
            dispatch(setTotalPrice(0))
        }
    }
}

// async for retriving product
export const retriveProductCart = () => {
    return dispatch => {
        dispatch(startFetchProduct());
        dispatch(checkToken()); // nitip ngikut ngecek auth
        const content = store.getState().order;

        ProductWhereIn(content).then(res => {
            // console.log(res.data)
            dispatch(fetchProduct(res.data));
            dispatch(successFetchProduct());
            dispatch(getTotalPrice(res.data));
            
        });
    }
}




