import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { FindTransactionId } from "../../api/index";
import Countdown from '../../components/Countdown/Countdown';
import Moment from 'react-moment';
import Popup from '../../components/Popup/Popup';

import { ListStatusOrder, TrackingShip } from '../../api/index';

import Steps, { Step } from 'rc-steps';
// import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import TrackingShipping from '../../components/TrackingShipping/TrackingShipping';



class OrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            date: null,
            isFetching: true,
            OrderInformation: null,
            listProduct: [],
            countdown: {
                days: 0,
                hours: 0,
                min: 0,
                sec: 0,
            },
            listStatusOrder: null,
            isPopUpOpen: false,
            tracking: null,
            isTracking: false
        }
    }

    handleTrackingClick(e) {
        e.preventDefault();
        this.setState({ isPopUpOpen: true, isTracking: true })
        const content = {
            id: this.state.data.id
        }

        TrackingShip(content).then(res => {
            this.setState({ tracking: res }, () => {
                this.setState({ isTracking: false })
            })
        })

    }

    togglePopup = (event) => {
        event.preventDefault();
        this.setState({ isPopUpOpen: false })
    }

    componentDidMount() {
        const transactionId = this.props.match.params.id;
        const content = {
            transactionId: transactionId
        }
        FindTransactionId(content).then(res => {
            this.setState({
                data: {
                    subtotal: res[0].subtotal + res[0].kode_unik - res[0].value,
                    ongkir: res[0].value,
                    id: res[0].id,
                    berat: res[0].weight / 1000,
                    diskon: res[0].diskon,
                    total: res[0].subtotal,
                    kodeunik: res[0].kode_unik,
                    transactionId: res[0].invoiceNumber,
                    orderDate: res[0].createdAt,
                    status: res[0].StatusOrder.statusName,
                    courier: res[0].courier,
                    address: res[0].Address_Customer.shipTo + " " + res[0].address + " " + res[0].Address_Customer.phone,
                    createdAt: res[0].createdAt,
                    dateconfirm: res[0].dateconfirm,
                    dateproses: res[0].dateproses,
                    datesend: res[0].datesend,
                    datereceived: res[0].datereceived,
                    statusPosition: res[0].status - 1,
                    bank: {
                        bankname: res[0].Bank.bankname,
                        norek: res[0].Bank.norek,
                        image: res[0].Bank.logo,
                        an: res[0].Bank.an,
                    }
                },
                listProduct: res[0].OrderProducts,
                date: res[0].expireDate,
                isFetching: false,
            })
        }).catch(err => {
            console.log(err)
        })

        ListStatusOrder().then(res => {
            this.setState({
                listStatusOrder: res.data
            })
        })
    }

    getFinishIcon = () => {
        const path = 'M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.' +
            '5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139' +
            '.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5' +
            '-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 ' +
            '55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33' +
            '.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99' +
            '.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.' +
            '7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 10' +
            '1.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 ' +
            '20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z';
        return (
            <svg
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 1024 1024"
                style={{ verticalAlign: '-.125em' }}
            >
                <path d={path} />
            </svg>
        );
    }

    endCountdownFunction = () => {
        // this.props.history.replace('/payment/failed/' + this.props.match.params.id)
    }

    linkToProduct = (event, value) => {
        event.preventDefault();
        this.props.history.push(`/product/${value.productId}-${value.name.replace(/\s+/g, '-').toLowerCase()}`)
    }


    render() {
        let listStatus = null;

        if (this.state.listStatusOrder !== null && this.state.data !== null) {
            listStatus = this.state.listStatusOrder.map((value, index) => {
                // keterangan Waktu setiap proses
                let desc = null;
                if (index === 0) {
                    desc = this.state.data.createdAt !== null ? <Moment locale="id" format="LLLL">{this.state.data.createdAt}</Moment> : null;
                } else if (index === 1) {
                    desc = this.state.data.dateproses !== null ? <Moment locale="id" format="LLLL">{this.state.data.dateproses}</Moment> : null;
                }
                else if (index === 2) {
                    desc = this.state.data.datesend !== null ? <><Moment locale="id" format="LLLL">{this.state.data.datesend}</Moment> <div onClick={(e) => this.handleTrackingClick(e)} style={{ cursor: 'pointer', background: 'whitesmoke', marginTop: '10px', padding: '2px', borderRadius: '5px', border: '1px solid grey' }}>Lacak</div></> : null;
                } else if (index === 3) {
                    desc = this.state.data.datereceived !== null ? <Moment locale="id" format="LLLL">{this.state.data.datereceived}</Moment> : null;
                }

                return <Step key={index} title={value.statusName} description={desc} />
            })
        }

        const icons = {
            finish: this.getFinishIcon(),
        };

        let listProductInCart = <div className="shipped-to-wrapper">
            <span style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="product-list-pict">
                    <div className="loading-background"
                        style={{
                            width: '100px',
                            height: '100px',
                            marginRight: '10px'
                        }}>
                    </div>

                </div>
                <div className="desc-product-wrapper" style={{ width: '100%' }}>
                    <div className="loading-background"
                        style={{
                            width: '80%',
                            height: '15px',
                            borderRadius: '10px'
                        }}>
                    </div>
                    <div className="loading-background"
                        style={{
                            width: '30%',
                            height: '15px',
                            borderRadius: '10px',
                            marginTop: '5px'
                        }}>
                    </div>
                    <div className="loading-background"
                        style={{
                            width: '40%',
                            height: '15px',
                            borderRadius: '10px',
                            marginTop: '5px'
                        }}>
                    </div>
                </div>


            </span>
        </div>;

        if (this.state.listProduct.length > 0) {
            listProductInCart = this.state.listProduct.map((value, key) => (
                // console.log(value)
                <div className="cart-list-product-wrapper" key={key}>
                    <div className="list-product-pict contain-pict" style={{ background: `url(${value.Product.Pictures[0].url_small})` }} ></div>
                    <div className="product-list-desc-wrapper">
                        <div className="title" onClick={(event) => this.linkToProduct(event, value)}>{value.Product.name}</div>
                        {/* <div className="category">Kehutanan</div> */}
                    </div>
                    <div className="price">
                        <NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} prefix={'Rp '} /> <span style={{ margin: '0px 3px' }}> x </span>{value.qty} =
                        <NumberFormat value={value.price * value.qty} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                    </div>
                </div>
            ))
        }

        let tracking_body = <>
            <TrackingShipping
                isTracking={this.state.isTracking}
                data={this.state.tracking} />
        </>

        return (
            <div>
                <Popup
                    onClosePopupHandler={this.togglePopup}
                    isOpen={this.state.isPopUpOpen}
                    headerTitle={"Lacak Pengiriman"}
                    body={tracking_body}
                />
                <div className="order-detail-wrapper">
                    <div className="inner-order-detail-wrapper">
                        <div className="main-order-detail">
                            <div className="tracking-status-wrapper">
                                {this.state.data !== null ? (
                                    <Steps current={this.state.data.statusPosition} labelPlacement="vertical" icons={icons}>
                                        {listStatus}
                                    </Steps>
                                ) : null}
                            </div>
                            <div className="product-list-wrapper">
                                {listProductInCart}
                            </div>
                            <div className="summary-order-detail">
                                <div className="number-order-list">
                                    <span className="label-sum">Sub Total</span>
                                    <span className="price-order">

                                        {this.state.data !== null ? (
                                            // console.log(this.state.data)
                                            <NumberFormat value={this.state.data.subtotal} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        ) :
                                            null}

                                    </span>
                                </div>
                                <div className="number-order-list">
                                    <span className="label-sum">Ongkos Kirim

                                    ({this.state.data !== null ? (
                                            this.state.data.berat
                                        ) :
                                            null} Kg)

                                    </span>
                                    <span className="price-order">
                                        {this.state.data !== null ? (
                                            <NumberFormat value={this.state.data.ongkir} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        ) : null}
                                    </span>
                                </div>
                                <div className="number-order-list">
                                    <span className="label-sum">Diskon</span>
                                    <span className="price-order">-
                                    {this.state.data !== null ? (
                                            <NumberFormat value={this.state.data.diskon} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        ) : null}
                                    </span>
                                </div>
                                <div className="number-order-list">
                                    <span className="label-sum">Kode Unik</span>
                                    <span className="price-order">-
                                    {this.state.data !== null ? (
                                            <NumberFormat value={this.state.data.kodeunik} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        ) : null}
                                    </span>
                                </div>
                                <div className="number-order-list-total">
                                    <span className="label-sum-total">Total</span>
                                    <span className="price-order-total">
                                        {this.state.data !== null ? (
                                            <NumberFormat value={this.state.data.total} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        ) : null}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="right-sidebar-order">

                            <div className="short-desc-order-wrapper">
                                <label> Id Transaksi</label>
                                <h4 style={{ margin: '0' }}>
                                    {
                                        this.state.data !== null ?
                                            (this.state.data.transactionId)
                                            : null
                                    }
                                </h4>
                            </div>

                            <div className="short-desc-order-wrapper">
                                <label> Download Invoice</label>
                                <a href={"/order/invoice/"+this.props.match.params.id} rel="noopener noreferrer" target="_blank">
                                    <h4 style={{ margin: '0' }}>
                                        Invoice
                                </h4>
                                </a>
                            </div>


                            <div className="short-desc-order-wrapper">
                                <label> Tanggal Order</label>
                                <h4 style={{ margin: '0' }}>
                                    {
                                        this.state.data !== null ? (
                                            <Moment locale="id" format="LLLL">{this.state.orderDate}</Moment>
                                        ) : null
                                    } WIB
                                </h4>
                            </div>

                            <div className="short-desc-order-wrapper">
                                <label> Status Pesanan</label>
                                <h4 style={{ margin: '0' }}>
                                    {this.state.data !== null ? (
                                        this.state.data.status
                                    ) : null}

                                </h4>

                                {this.state.data && this.state.data.statusPosition === 0 ? (
                                    <Countdown
                                        deadline={this.state.date}
                                        deadFunction={(event) => { this.endCountdownFunction(event) }}
                                        redirectUrl="/"
                                    />
                                ) : null}


                                {/* <div className="clock-wrapper clock-order">
                                    <div className="clock-div">
                                        <div className="number">{this.state.countdown.hours}</div>
                                        <div className="label">Jam</div>
                                    </div>
                                    <div className="devide">:</div>
                                    <div className="clock-div">
                                        <div className="number">{this.state.countdown.min}</div>
                                        <div className="label">Menit</div>
                                    </div>
                                    <div className="devide">:</div>
                                    <div className="clock-div">
                                        <div className="number">{this.state.countdown.sec}</div>
                                        <div className="label">Detik</div>
                                    </div>
                                </div> */}

                            </div>

                            <div className="short-desc-order-wrapper">
                                <label> Metode Pembayaran</label>
                                <h4 style={{ margin: '0' }}>
                                    {this.state.data !== null ? (
                                        this.state.data.bank.bankname + " " + this.state.data.bank.norek + " a/n " + this.state.data.bank.an
                                    ) : null}
                                </h4>
                            </div>

                            <div className="short-desc-order-wrapper">
                                <label> Metode Pengiriman</label>
                                <h4 style={{ margin: '0' }}>
                                    {this.state.data !== null ? (
                                        this.state.data.courier
                                    ) : null}
                                </h4>
                            </div>

                            <div className="short-desc-order-wrapper">
                                <label> Alamat Pengiriman</label>
                                <p style={{ margin: '0' }}>
                                    {this.state.data !== null ? (
                                        this.state.data.address
                                    ) : null}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderDetail