import React, { Component } from 'react';
import SliderFront from '../../components/SliderFront/SliderFront';
import ProductSlider from '../../components/ProductSlider/ProductSlider';
import { SearchQuery, SearchJurnal } from '../../api/index';
import queryString from 'query-string'


class FrontPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bestSeller: [],           
            mostwanted:[],
            newest:[],
            highestprice:[],
            lowestprice:[],
            diskon:[],
            newproduct:[]
        }
    }

    fetchProduct = (payload,sortby) => {
        SearchQuery(payload).then(result => {           
            this.setState({
                [sortby]: result.result,                
            })
        });
    }

    fetchProductJurnal = (payload,sortby) => {
        SearchJurnal(payload).then(result => {           
            this.setState({
                [sortby]: result.result,                
            })
        });
    }



    doingSearch = (sortby) => {
        this.setState({ isFetching: true })
        const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

        let byName = '';
        let byminPrice = '';
        let bymaxPrice = '';
        let byCategory = '';
        let bySort = sortby;
        let page = '';

        if (query.name !== undefined) {
            byName = query.name ? query.name : '';
            byminPrice = query.minprice ? query.minprice : '';
            bymaxPrice = query.maxprice ? query.maxprice : '';
            byCategory = query.category ? query.category : '';
            bySort = query.sortby ? query.sortby : '';
            page = query.page ? query.page : '';
            this.setState({ queryName: byName });
        }

        // jika klik dari menu
        if (this.props.match.params.nameCat !== undefined) {
            byCategory = this.props.match.params.nameCat
            page = query.page ? query.page : 1;
            this.setState({ queryName: "Kategori " + byCategory })
        }

        this.setState({ page: Math.ceil(query.page) })

        const content = {
            name: byName,
            minprice: byminPrice,
            maxprice: bymaxPrice,
            category: byCategory,
            sortby: bySort,
            page: page
        }

        this.fetchProduct(content,sortby);
    }



    doingSearchJurnal = (sortby) => {
        this.setState({ isFetching: true })
        const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

        let byName = '';
        let byminPrice = '';
        let bymaxPrice = '';
        let byCategory = '';
        let bySort = sortby;
        let page = '';

        if (query.name !== undefined) {
            byName = query.name ? query.name : '';
            byminPrice = query.minprice ? query.minprice : '';
            bymaxPrice = query.maxprice ? query.maxprice : '';
            byCategory = query.category ? query.category : '';
            bySort = query.sortby ? query.sortby : '';
            page = query.page ? query.page : '';
            this.setState({ queryName: byName });
        }

        // jika klik dari menu
        if (this.props.match.params.nameCat !== undefined) {
            byCategory = this.props.match.params.nameCat
            page = query.page ? query.page : 1;
            this.setState({ queryName: "Kategori " + byCategory })
        }

        this.setState({ page: Math.ceil(query.page) })

        const content = {
            name: byName,
            minprice: byminPrice,
            maxprice: bymaxPrice,
            category: byCategory,
            sortby: bySort,
            page: page
        }

        this.fetchProductJurnal(content,sortby);
    }

    // doingSearchWakaf(){
    //     this.setState({ isFetching: true })
    //     const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

    //     let byName = '';
    //     let byminPrice = '';
    //     let bymaxPrice = '';
    //     let byCategory = 'wakaf';
    //     let bySort = 'lowestprice';
    //     let page = '';

    //     if (query.name !== undefined) {
    //         byName = query.name ? query.name : '';
    //         byminPrice = query.minprice ? query.minprice : '';
    //         bymaxPrice = query.maxprice ? query.maxprice : '';
    //         byCategory = query.category ? query.category : '';
    //         bySort = query.sortby ? query.sortby : '';
    //         page = query.page ? query.page : '';
    //         this.setState({ queryName: byName });
    //     }

    //     // jika klik dari menu
    //     if (this.props.match.params.nameCat !== undefined) {
    //         byCategory = this.props.match.params.nameCat
    //         page = query.page ? query.page : 1;
    //         this.setState({ queryName: "Kategori " + byCategory })
    //     }

    //     this.setState({ page: Math.ceil(query.page) })

    //     const content = {
    //         name: byName,
    //         minprice: byminPrice,
    //         maxprice: bymaxPrice,
    //         category: byCategory,
    //         sortby: bySort,
    //         page: page
    //     }       

    //     this.fetchProduct(content,'wakaf');
    // }

    componentDidMount() {
        this.doingSearch('newest');
        this.doingSearch('bestSeller');
        this.doingSearch('lowestprice');
        this.doingSearch('mostwanted');
        this.doingSearch('diskon');
        this.doingSearch('newproduct');
        this.doingSearchJurnal('newest');
        
        // Program Wakaf
        // this.doingSearchWakaf();
    }

    render() {
        return (
            <div>
                <SliderFront />
                <ProductSlider
                    title="Diskon"
                    products={this.state.diskon}
                    hyperlink="/product/search/?name=&page=1&sortby=diskon"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139569/buku-buku-diskon.jpg"
                />
                <ProductSlider
                    title="Terbaru"
                    products={this.state.newproduct}
                    hyperlink="/product/newproduct/?name=&page=1&sortby=newproduct"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139568/buku-buku-terbaru.jpg"
                />
                <ProductSlider
                    title="Terpopuler"
                    products={this.state.lowestprice}
                    hyperlink="/product/search/?name=&page=1&sortby=lowestprice"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935148/slider/buku-terpopuler-ipbpress.jpg"
                />
                <ProductSlider
                    title="Paling Dicari"
                    products={this.state.mostwanted}
                    hyperlink="/product/search/?name=&page=1&sortby=mostwanted"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935146/slider/buku-palingdicari-ipbpress.jpg"
                />
                <ProductSlider
                    title="Terlaris"
                    products={this.state.bestSeller}
                    hyperlink="/product/search/?name=&page=1&sortby=bestSeller"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935149/slider/buku-terlaris-ipbpress.jpg"
                />
                 <ProductSlider
                    title="Katalog Jurnal"
                    products={this.state.newest}
                    hyperlink="/product/jurnalproduct/?name=&page=1&sortby=newproduct"
                    thumbnail="https://res.cloudinary.com/blst/image/upload/v1685345491/SideBar_Jurnal_5_.png"
                />
            </div>
        )
    }
}

export default FrontPage;