import React, { Component } from 'react';
import { FindTransactionId } from "../../../api/index";
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';


class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: null,
            date: null,
            isFetching: true,
            OrderInformation: null,
            listProduct: [],
            countdown: {
                days: 0,
                hours: 0,
                min: 0,
                sec: 0,
            },
            listStatusOrder: null,
            isPopUpOpen: false,
            tracking: null,
            isTracking: false
        }
    }

    fecthData() {
        const transactionId = this.props.match.params.id;
        const content = {
            transactionId: transactionId
        }

        FindTransactionId(content).then(res => {
            this.setState({
                data: {
                    subtotal: res[0].subtotal + res[0].kode_unik - res[0].value,
                    ongkir: res[0].value,
                    id: res[0].id,
                    berat: res[0].weight / 1000,
                    diskon: res[0].diskon,
                    total: res[0].subtotal,
                    kodeunik: res[0].kode_unik,
                    transactionId: res[0].invoiceNumber,
                    orderDate: res[0].createdAt,
                    status: res[0].StatusOrder.statusName,
                    courier: res[0].courier,
                    address: res[0].address,
                    shipTo: res[0].Address_Customer.shipTo,
                    phone: res[0].Address_Customer.phone,
                    createdAt: res[0].createdAt,
                    dateconfirm: res[0].dateconfirm,
                    dateproses: res[0].dateproses,
                    datesend: res[0].datesend,
                    datereceived: res[0].datereceived,
                    statusPosition: res[0].status - 1,
                    bank: {
                        bankname: res[0].Bank.bankname,
                        norek: res[0].Bank.norek,
                        image: res[0].Bank.logo,
                        an: res[0].Bank.an,
                    }
                },
                listProduct: res[0].OrderProducts,
                date: res[0].expireDate,
                isFetching: false,
                isLoading: false
            })
        }).catch(err => {
            console.log(err)
        })

    }

    componentDidMount() {
        this.fecthData();
    }

    render() {
        var { isLoading } = this.state;
        return (
            <>
                {!isLoading ? (
                    <div className="invoice-wrapper">
                        {/* <div className="invoice-header" style={{ textAlign: 'center' }}>
                    <img width="200" src="../../ipbpresslogo.png" alt="" />
                </div>


                <table style={{ color: '#343030', fontSize: '12px', marginTop: '25px' }}>
                    <tr>
                        <td>
                            <b>Tanggal</b>
                        </td>
                        <td>
                            22 Agustus 2019
                            </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Pembayaran</b>
                        </td>
                        <td>
                            BRI
                            </td>
                    </tr>
                </table>



                <table style={{ width: '100%', border: 'thin solid #979797', borderBottom: 'none', borderRadius: '4px', color: '#343030', marginTop: '20px' }}>
                    <tr style={{ backgroundColor: 'rgba(242, 242, 242, 0.74)' }}>
                        <td colSpan="3" style={{ fontWeight: '600', fontSize: '16px', padding: '10px' }}>
                            Ringkasan Pembayaran
                            </td>
                    </tr>
                    <tr>
                        <td>
                            Barang 1
                            </td>
                        <td>
                            1 pcs @ Rp 20.000
                            </td>
                        <td align="right">
                            Rp. 20.000
                            </td>
                    </tr>
                    <tr>
                        <td>
                            Barang 1
                            </td>
                        <td>
                            1 pcs @ Rp 20.000
                            </td>
                        <td align="right">
                            Rp. 20.000
                            </td>
                    </tr>
                </table>
                <table style={{
                    width: '100%',
                    border: 'thin solid #979797',
                    borderRadius: '4px',
                    color: '#343030',
                    fontWeight: '600', fontSize: '16px', padding: '10px'
                }}>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                Subtotal Belanja
                            </td>
                            <td align="right">
                                Rp. 20.000
                            </td>
                        </tr>
                    </tbody>

                </table>

                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="50%">

                            </td>
                            <td width="50%">
                                <table style={{
                                    width: '430px', marginTop: '15px',
                                    padding: '15px',
                                    borderRadius: '4px',
                                    backgroundColor: 'rgba(242, 242, 242, 0.74)',
                                    border: 'thin solid rgba(0, 0, 0, 0.54)',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: 'rgb(0, 0, 87)'
                                }}>
                                    <tr>
                                        <td>Total Pembayaran</td>
                                        <td align="right">Rp 294.615</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" style={{ marginTop: '40px', borderBottom: '1px dashed grey' }}>
                    <tbody>
                        <tr>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" style={{ marginTop: '10px', borderBottom: '1px dashed grey' }}>
                    <tbody>
                        <tr>
                            <td></td>
                        </tr>
                    </tbody>
                </table> */}
                        <div className="no-print" onClick={() => window.print()} style={{
                            width: '100%',
                            textAlign: 'right'
                        }}>
                            <span style={{cursor:'pointer'}}>
                                <i className="fa fa-print" style={{ marginRight: '5px' }} aria-hidden="true"></i> Cetak
                            </span>
                        </div>

                        <div className="invoice-detail">
                            <table width="100%" style={{
                                width: '790px',
                                padding: '25px 32px',
                                color: '#343030'
                            }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <table width="100%" style={{
                                                paddingBottom: '20px', borderBottom: 'thin dashed #cccccc'
                                            }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '57%', verticalAlign: 'top' }}>
                                                            <table width="100%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan="2">
                                                                            <img style={{
                                                                                marginBottom: '15px', width: '147px'
                                                                            }} width="200" src="../../ipbpresslogo.png" alt="" />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="2" style={{
                                                                            fontSize: '14px'
                                                                        }}>

                                                                            <span style={{ fontWeight: '600' }}>
                                                                                Nomor Invoice :
                                                                    </span>

                                                                            <span style={{
                                                                                color: '#140657',
                                                                                fontWeight: '600'
                                                                            }}>
                                                                                {this.state.data.transactionId}
                                                                            </span>

                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="2" style={{
                                                                            fontSize: '12px', padding: '8px 0'
                                                                        }}>
                                                                            Diterbitkan atas nama:
                                                                </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{
                                                                            fontSize: '12px',
                                                                            fontWeight: '600',
                                                                            paddingBottom: '6px',
                                                                            width: '80px'
                                                                        }}>
                                                                            Penjual
                                                                </td>
                                                                        <td style={{
                                                                            fontSize: '12px',
                                                                            paddingBottom: '6px'
                                                                        }}>
                                                                            IPB Press
                                                                </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{
                                                                            fontSize: '12px',
                                                                            fontWeight: '600',
                                                                            paddingBottom: '6px',
                                                                            width: '80px'
                                                                        }}>
                                                                            Tanggal
                                                                </td>
                                                                        <td style={{
                                                                            fontSize: '12px',
                                                                            paddingBottom: '6px'
                                                                        }}>
                                                                            <Moment locale="id" format="LLLL">{this.state.orderDate}</Moment>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style={{
                                                            width: '43%',
                                                            verticalAlign: 'top',
                                                            paddingLeft: '30px'
                                                        }}>
                                                            <table width="100%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{
                                                                            fontWeight: '600',
                                                                            fontSize: '14px',
                                                                            paddingBottom: '8px'
                                                                        }}>
                                                                            Tujuan Pengiriman:
                                                                </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{
                                                                            fontSize: '12px',
                                                                            paddingBottom: '20px'
                                                                        }}>
                                                                            <span style={{
                                                                                marginBottom: '3px',
                                                                                fontWeight: '600',
                                                                                display: 'block'
                                                                            }}>
                                                                                {this.state.data.shipTo}
                                                                            </span>
                                                                            <div>
                                                                                {this.state.data.address} <br />
                                                                                {this.state.data.phone}

                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    {/* Bagian Detail */}
                                    <tr>
                                        <td>
                                            <table width="100%" style={{
                                                border: 'thin dashed rgba(0, 0, 0, 0.34)', borderRadius: '4px; color: #343030', marginTop: '20px'
                                            }}>
                                                <tbody>
                                                    <tr style={{
                                                        backgroundColor: 'rgba(242, 242, 242, 0.74)',
                                                        fontSize: '14px',
                                                        fontWeight: '600'
                                                    }}>
                                                        <td style={{ padding: '10px 15px' }}>Nama Produk</td>
                                                        <td style={{ padding: '10px 15px' }}>Jumlah</td>
                                                        <td style={{ padding: '10px 15px' }}>Berat</td>
                                                        <td style={{ padding: '10px 15px' }}>Harga Barang</td>
                                                        <td style={{ padding: '10px 15px' }}>Sub Total</td>
                                                    </tr>

                                                    {this.state.listProduct.map((value, index) => (
                                                        <tr style={{ fontSize: '14px' }} key={index}>
                                                            <td width="330" style={{
                                                                padding: '15px',
                                                                fontWeight: '600',
                                                                wordBreak: 'break-word'
                                                            }}>
                                                                {value.Product.name}
                                                                <div style={{ margin: '10px 0 0' }}></div>
                                                            </td>
                                                            <td valign="top" style={{ padding: '15px', textAlign: 'center' }}>
                                                                {value.qty}
                                                            </td>
                                                            <td valign="top" style={{ padding: '15px', textAlign: 'center', whiteSpace: 'nowrap' }}>
                                                                {value.weight} gr
                                                            </td>
                                                            <td valign="top" style={{ padding: '15px', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                                <NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                            </td>
                                                            <td valign="top" style={{ padding: '15px', whiteSpace: 'nowrap' }}>
                                                                <NumberFormat value={value.price * value.qty} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                            </td>
                                                        </tr>
                                                    ))}


                                                    <tr>
                                                        <td></td>
                                                        <td colSpan="4">
                                                            <table width="100%" style={{
                                                                paddingRight: '15px',
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan="2">
                                                                            <div style={{
                                                                                borderBottom: 'thin solid #e0e0e0'
                                                                            }}></div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{
                                                                            padding: '15px'
                                                                        }}>
                                                                            Subtotal Harga Produk
                                                                </td>
                                                                        <td style={{
                                                                            padding: '15px 0 15px',
                                                                            textAlign: 'right',
                                                                        }}>
                                                                            <NumberFormat value={this.state.data.subtotal} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={{ float: 'right' }}>
                                                {/* Subtotal Ongkir */}
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <table width="100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '50%' }}></td>
                                                                            <td style={{ width: '50%' }}>
                                                                                <table width="100%"
                                                                                    style={{
                                                                                        width: '430px',
                                                                                        marginTop: '15px',
                                                                                        padding: '15px',
                                                                                        borderRadius: '4px',
                                                                                        border: 'thin dashed #cccccc',
                                                                                        fontSize: '14px',
                                                                                        fontWeight: '600'
                                                                                    }}
                                                                                >
                                                                                    <tbody>
                                                                                        <tr style={{
                                                                                            fontWeight: 'normal',
                                                                                            fontSize: '12px'
                                                                                        }}>
                                                                                            <td style={{ paddingBottom: '10px' }}>
                                                                                                {this.state.data.courier} (Berat: {this.state.data.berat} Kg)
                                                                                    </td>
                                                                                            <td style={{
                                                                                                paddingBottom: '10px',
                                                                                                textAlign: 'right',
                                                                                                whiteSpace: 'nowrap',
                                                                                                verticalAlign: 'top'
                                                                                            }}>
                                                                                                <NumberFormat value={this.state.data.ongkir} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td style={{
                                                                                                borderTop: 'thin solid #e0e0e0', paddingTop: '10px'
                                                                                            }}>
                                                                                                Subtotal Ongkos Kirim
                                                                                    </td>
                                                                                            <td style={{
                                                                                                borderTop: 'thin solid #e0e0e0',
                                                                                                paddingTop: '10px',
                                                                                                textAlign: 'right',
                                                                                                whiteSpace: 'nowrap',
                                                                                            }}>
                                                                                                <NumberFormat value={this.state.data.ongkir} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        {/* Biaya Lain-Lain */}
                                                        <tr>
                                                            <td>
                                                                <table width="100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '50%' }}></td>
                                                                            <td style={{ width: '50%' }}>
                                                                                <table width="100%" style={{
                                                                                    width: '430px',
                                                                                    marginTop: '15px',
                                                                                    padding: '15px',
                                                                                    borderRadius: '4px',
                                                                                    border: 'thin dashed #cccccc',
                                                                                    fontSize: '12px',
                                                                                    fontWeight: '600',
                                                                                }}>
                                                                                    <tbody>
                                                                                        <tr style={{
                                                                                            fontWeight: 'normal'
                                                                                        }}>
                                                                                            <td style={{
                                                                                                paddingBottom: '10px'
                                                                                            }}>
                                                                                                Kode Unik
                                                                                    </td>
                                                                                            <td style={{
                                                                                                paddingBottom: '10px',
                                                                                                textAlign: 'right',
                                                                                                whiteSpace: 'nowrap',
                                                                                                verticalAlign: 'top',
                                                                                            }}>
                                                                                                - <NumberFormat value={this.state.data.kodeunik} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr style={{ fontSize: '14px' }}>
                                                                                            <td style={{
                                                                                                borderTop: 'thin solid #e0e0e0',
                                                                                                paddingTop: '10px',
                                                                                            }}>
                                                                                                Subtotal Biaya Lainnya
                                                                                    </td>
                                                                                            <td style={{
                                                                                                borderTop: 'thin solid #e0e0e0',
                                                                                                paddingTop: '10px',
                                                                                                textAlign: 'right',
                                                                                                whiteSpace: 'nowrap',
                                                                                            }}>
                                                                                                - <NumberFormat value={this.state.data.kodeunik} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table width="100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '50%' }}></td>
                                                                            <td style={{ width: '50%' }}>
                                                                                <table width="100%" style={{
                                                                                    width: '430px',
                                                                                    marginTop: '15px',
                                                                                    padding: '15px',
                                                                                    borderRadius: '4px',
                                                                                    border: 'thin solid rgba(0, 0, 0, 0.54)',
                                                                                    fontSize: '14px',
                                                                                    fontWeight: '600',
                                                                                }}>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Total Pembayaran
                                                                                    </td>
                                                                                            <td style={{ textAlign: 'right' }}>
                                                                                                <NumberFormat value={this.state.data.total} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                ) : (
                        <div style={{
                            width: '100%',
                            height: '80vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <h1>Generating Invoice...</h1>
                        </div>
                    )}
            </>
        )
    }
}

export default Invoice;