import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
// import { FindTransactionId } from "../../api/index";
// import Moment from 'react-moment';
import 'moment/locale/id';
// import NumberFormat from 'react-number-format';
import { Switch } from "react-router-dom";
import PrivateRoute from "../../services/PrivateRoute";
import jwt from 'jsonwebtoken';

import { connect } from 'react-redux';
import PurchaseList from '../PurchaseList/PurchaseList';
import * as actionCreator from '../../redux-store/redux-actions/index';





class ProfilPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    verifyToken = () => {
        const now = new Date();
        const jwtdate = new Date(this.props.authData.expire);        
        if (jwtdate > now) {
            const token = this.props.authData.tokenId;
            const step = jwt.verify(token, "customertoken");           
            return step.firstname+ " " + step.lastname;
        } else {
            return null
        }
    }


    render() {

        let firstname = this.verifyToken();        

        return (
            <div className="profile-page-wrapper">
                <div className="inner-profile-page-wrapper">
                    <div className="left-sidebar-profile">
                        <div className="short-desc-profile-wrapper flex-row">
                            <div className="profpic loading-background">
                            </div>
                            <div className="name">{firstname}</div>
                        </div>
                        <div className="menu-profile-side">
                            <ul>
                                <NavLink to="/user/purchase" style={{ textDecoration: 'none' }}>
                                    <li>Pesanan Saya</li>
                                </NavLink>
                                {/* <NavLink to="/user/profile" style={{ textDecoration: 'none' }}>
                                    <li>Profil</li>
                                </NavLink> */}
                                <NavLink to="/logout" style={{ textDecoration: 'none' }}>                                   
                                    <li className="logout-li">Logout</li>
                                </NavLink>

                            </ul>

                        </div>
                    </div>
                    <div className="main-profile-page">
                        <Switch>
                            <PrivateRoute path="/user/purchase" exact isAuth={this.props.auth} component={PurchaseList}></PrivateRoute>
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isModalAuthOpen: state.auth.modalShow,
        auth: state.auth.isAuth,
        authData:state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OpenAuthModal: () => dispatch(actionCreator.openModalAuth())
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilPage));