export const ADD_ORDER = "ADD_ORDER";
export const INC_ORDER = "INC_ORDER";
export const DEC_ORDER = "DEC_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";

export const REMOVE_ORDER = "REMOVE_ORDER";
export const FLUSH_ORDER = "FLUSH_ORDER";

export const START_FETCHPRODUCT = "START_FETCHPRODUCT";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const SUCCESS_FETCHPRODUCT = "SUCCESS_FETCHPRODUCT";
export const FAILED_FETCHPRODUCT = "FAILED_FETCHPRODUCT";

export const SET_TOTALPRICE = "SET_TOTALPRICE";

export const OPEN_AUTHMODAL = "OPEN_AUTHMODAL";
export const CLOSE_AUTHMODAL = "CLOSE_AUTHMODAL";
export const REGISTER_REQUEST = "USERS_REGISTER_REQUEST";
export const REGISTER_SUCCESS = "USERS_REGISTER_SUCCESS";
export const REGISTER_FAILURE = "USERS_REGISTER_FAILURE";

export const LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";

export const LOGOUT = "USERS_LOGOUT";

